import React, { useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "../../../component/frontend/loader/loader";
import { Header } from "../../../component/frontend/header/header";
import { Link } from "react-router-dom";
import { Footer } from "../../../component/frontend/footer/footer";
import Select from "react-select";
import BIZZ_LOGO from "../../../../assets/images/business-logo-optional.png";
import { constants, baseUrl, CustomValueContainer } from "../../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { deleteHeaderImage, getProfileDetail, getState, updateBusinessProfileDetail } from "../../../../duck/profile/profile.action";
import { PreviewContactForm } from "./PreviewContactForm";
import { addVisibleField, createBusinessForm, publishBusinessForm, removeVisibleField, storeVisibleField, updateVisibleField, viewBusinessForm } from "../../../../duck/businessForm/businessForm.action";
import Modal from "react-bootstrap/Modal";
import ERROR_ICON from "../../../../assets/images/error-icn.svg";
import { addService, listServiceWithSource } from "../../../../duck/lead/lead.action";
import _ from 'lodash';
import { validateInputs } from '../../../../common/validation';
import { fieldValidator, usePrevious, setImagePath, decodeToString } from '../../../../common/custom';
import { getUserDetails } from "../../../../storage/user";
import DELETE_SVG from "../../../../assets/images/delete-button.svg";
import ALERT_ICON from "../../../../assets/images/alert-icn.svg";
import Swal from 'sweetalert2'
import { errorNotification } from "../../../../common/notification-alert";

const CustomizeContact = () => {
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState(false);
  const [allAddress, setAllAddress] = useState("-");
  const [selectedServices, setSelectedServices] = useState([]);
  const businessProfileDetailData = useSelector(state => state.profile.businessProfileDetailData);
  const prevBusinessProfileDetailData = usePrevious({ businessProfileDetailData });
  const profileDetail = useSelector((state) => state.profile.getProfileDetailData.data);
  const viewBusinessFormData = useSelector((state) => state.businessForm.viewBusinessFormData);
  const visibleFields = useSelector((state) => state.businessForm.visibleFields);
  const contactForm = viewBusinessFormData?.contact_form ? JSON.parse(viewBusinessFormData.contact_form) : null;
  const formData = contactForm?.contact_form || {};
  const businessData = contactForm?.business_details || {};
  const [isServicesAdded, setIsServicesAdded] = useState(false);
  const servicesData = contactForm?.services || [];
  const [businessInfo, setBusinessInfo] = useState({
    name: true,
    nameVal: profileDetail?.organization?.name,
    email: true,
    emailVal: profileDetail?.organization?.email,
    phone: false,
    phoneVal: profileDetail?.organization?.phone,
    website: true,
    websiteVal: profileDetail?.organization?.website,
    address: false,
    addressVal: allAddress,
    logo: true,
    banner: true,
    licence: false,
    licenceVal: profileDetail?.organization?.licence,
    ...businessData,
    logoVal: baseUrl[0] + profileDetail?.organization?.company_logo,
    bannerVal: baseUrl[0] + profileDetail?.organization?.header_image,
  });
  const getProfileDetailData = useSelector((state) => state.profile.getProfileDetailData);
  const prevGetProfileDetailData = usePrevious({ getProfileDetailData });
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [businessProfileModalShow, setBusinessProfileModalShow] = useState(false);
  const [businessProfileMessage, setBusinessProfileMessage] = useState('');
  const [businessProfileState, setBusinessProfileState] = useState({
    businessName: '', email: '', phone: '', city: '', streetAddress: '', state: '', zip: '', country: { value: 'USA', label: 'USA' }, website: '',
    businessNameCls: '', emailCls: '', phoneCls: '', streetAddressCls: '', cityCls: '', stateCls: '', zipCls: '', countryCls: '', companyLogo: '', companyLogoSrc: '',
    businessNameErr: '', emailErr: '', phoneErr: '', streetAddressErr: '', cityErr: '', stateErr: '', zipErr: '', countryErr: '',
    correctInput: '', wrongInput: constants.WRONG_INPUT, orgId: 1, companyLogoErr: '', companyLogoCls: '', license_no: '', bonded: 0, insured: 0,
    companyHeader: '', companyHeaderSrc: '', companyHeaderErr: '', companyHeaderCls: '',
  })
  const [businessProfileEditeState, setBusinessProfileEditeState] = useState(false);
  const [twoFactorVerifyModalShow, setTwoFactorVerifyModalShow] = useState(false);
  const [twoFactorState, setTwoFactorState] = useState({
    enable: false,
  })
  // const [visibleFields, setVisibleFields] = useState({
  //   ...formData,
  //   serviceInterested: { no: 1, label: "Service Interested In", required: true },
  //   firstName: { no: 2, label: "First Name", required: true },
  //   lastName: { no: 3, label: "Last Name", required: true },
  //   email: { no: 4, label: "Email", required: true },
  // });

  const checkValidation = (field, value, type, maxLength, minLength, fieldType) => {
    return fieldValidator(field, value, type, "password", maxLength, minLength, fieldType)
  }

  const setServiceValue = (e, type, maxLength, minLength) => {
    let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength)
    setServiceState({ ...serviceState, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName });
    setServiceOptionMessage('');
  }
  const [services, setServices] = useState({});
  // const [services, setServices] = useState(() => {
  //     const uniqueServices = [
  //         ...servicesData.filter(
  //             (service, index, self) => 
  //                 index === self.findIndex((t) => t.value === service.value) // Ensure uniqueness by value
  //         ),
  //     ];
  //     return uniqueServices;
  // });
  const serviceTextRef = useRef();
  const [serviceState, setServiceState] = useState({
    service: '', serviceCls: '', serviceErr: '', servicesListOptions: [],
  });

  const [serviceOptionMessage, setServiceOptionMessage] = useState('');

  const addServiceData = useSelector(state => state.lead.addServiceData);
  const prevAddServiceData = usePrevious({ addServiceData });
  const listServiceWithSourceData = useSelector(state => state.lead.listServiceWithSourceData);
  const prevListServiceWithSourceData = usePrevious({ listServiceWithSourceData });
  const [addServiceModalShow, setAddServiceModalShow] = useState(false);

  const [formDetails, setFormDetails] = useState({
    serviceInterested: { no: "1", label: "Service Interested In", required: true, visible: formData?.serviceInterested !== undefined ? true : true },
    firstName: { no: "2", label: "First Name", required: true, visible: formData?.firstName !== undefined ? true : true },
    lastName: { no: "3", label: "Last Name", required: true, visible: formData?.lastName !== undefined ? true : true },
    email: { no: "4", label: "Email", required: true, visible: formData?.email !== undefined ? true : true },
    phone: { no: "5", label: "Phone Number", required: false, visible: formData?.phone !== undefined ? true : false },
    title: { no: "6", label: "Title", required: false, visible: formData?.title !== undefined ? true : false },
    zipCode: { no: "7", label: "Zip Code", required: false, visible: formData?.zipCode !== undefined ? true : false },
    city: { no: "8", label: "City", required: false, visible: formData?.city !== undefined ? true : false },
    address1: { no: "9", label: "Street Address 1", required: false, visible: formData?.address1 !== undefined ? true : false },
    address2: { no: "10", label: "Street Address 2", required: false, visible: formData?.address2 !== undefined ? true : false },
    additionalInfo: { no: "11", label: "Additional Info", required: false, visible: formData?.additionalInfo !== undefined ? true : false },
  });

  const formatAddress = (organization) => {
    let addressParts = [];
    const addressFields = ["street_address", "city", "state", "country", "zip"];
    addressFields.forEach((field) => {
      if (organization[field]) addressParts.push(organization[field]);
    });
    return addressParts.join(", ");
  };

  const handleBusinessInfoChange = (field) => {
    setBusinessInfo((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleFormDetailChange = (field, info) => {
    setFormDetails((prev) => ({
      ...prev,
      [field]: { ...prev[field], visible: !prev[field].visible },
    }));

    if (info.visible) {
      dispatch(removeVisibleField(field));
    } else {
      dispatch(addVisibleField(field, { ...info, visible: true }));
    }
    // if (info.visible) {
    //   setVisibleFields((prev) => {
    //     const newVisibleFields = { ...prev };
    //     delete newVisibleFields[field];
    //     return newVisibleFields;
    //   });
    // } else {
    //   setVisibleFields((prev) => ({ ...prev, [field]: info }));
    // }
  };

  const handleSelectChange = (field, info, selectedOption) => {
    const newRequiredState = selectedOption.value === "required";
    setFormDetails((prev) => ({
      ...prev,
      [field]: { ...prev[field], required: newRequiredState },
    }));

    if (info.visible) {
      // setVisibleFields((prev) => ({
      //   ...prev,
      //   [field]: { ...info, required: newRequiredState },
      // }));
      dispatch(updateVisibleField(field, info, newRequiredState));
    }

  };
  // Delete company logo
  const deleteCompanyLogo = (e) => {
    e.preventDefault();
    setBusinessProfileState({ ...businessProfileState, companyLogo: null, companyLogoSrc: null, companyLogoErr: '', companyLogoCls: '' })
  }

  const onSelectFile = e => {
    if (e && e.target.value.length !== 0) {
      setBusinessProfileState({ ...businessProfileState, companyLogo: e.target.files[0], companyLogoSrc: URL.createObjectURL(e.target.files[0]), companyLogoErr: '', companyLogoCls: '' })
    }
  };
  const onSelectHeaderFile = e => {
    if (e && e.target.value.length !== 0) {
      setBusinessProfileState({ ...businessProfileState, companyHeader: e.target.files[0], companyHeaderSrc: URL.createObjectURL(e.target.files[0]), companyHeaderErr: '', companyHeaderCls: '' })
    }
  };
  // Delete company Business Banner
  const deleteHeaderImageFunc = () => {
    setBusinessProfileState({ ...businessProfileState, companyHeader: '', companyHeaderSrc: '', companyHeaderErr: '', companyHeaderCls: '', })
    dispatch(deleteHeaderImage(
      { header_image: profileDetail.organization.header_image }
    ))
  }
  const onChangeCountry = (data) => {
    setBusinessProfileState({ ...businessProfileState, country: data, state: [] })
    dispatch(getState({ country_id: data.id }))
  }
  // Set Business Profile Values
  const setBusinessProfileValue = (e, type, maxLength, minLength) => {
    let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength)
    setBusinessProfileState({ ...businessProfileState, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName });
    setBusinessProfileMessage('');
  }
  const openBusinessProfileModal = (e) => {
    e.currentTarget.blur();
    if (profileDetail && profileDetail.organization && profileDetail.organization.name !== "Default") {
      dispatch(getState({ country_id: userData && userData.planData.country_id }))
      setBusinessProfileState({
        ...businessProfileState,
        businessName: profileDetail && profileDetail.organization.name !== null ? profileDetail.organization.name : '',
        email: profileDetail && profileDetail.organization.email !== null ? profileDetail.organization.email : '',
        phone: profileDetail && profileDetail.organization.phone !== null ? profileDetail.organization.phone : '',
        streetAddress: profileDetail && profileDetail.organization.street_address !== null ? profileDetail.organization.street_address : '',
        city: profileDetail && profileDetail.organization.city !== null ? profileDetail.organization.city : '',
        zip: profileDetail && profileDetail.organization.zip !== null ? profileDetail.organization.zip : '',
        website: profileDetail && profileDetail.organization.website !== null ? profileDetail.organization.website : '',
        license_no: profileDetail && profileDetail.organization.license_no !== null ? profileDetail.organization.license_no : '',
        state: profileDetail && profileDetail.organization.state !== null ? { value: profileDetail.organization.state, label: profileDetail.organization.state } : '',
        country: profileDetail && profileDetail.organization.country !== null ? { value: profileDetail.organization.country, label: profileDetail.organization.country } : '',
        orgId: profileDetail && profileDetail.organization.id !== null ? profileDetail.organization.id : 1,
        companyLogoSrc: profileDetail && profileDetail.organization.company_logo !== null ? baseUrl[0] + profileDetail.organization.company_logo : '',
        companyHeaderSrc: profileDetail && profileDetail.organization.header_image !== null ? baseUrl[0] + profileDetail.organization.header_image : '',
        bonded: profileDetail && profileDetail.organization.bonded !== null ? profileDetail.organization.bonded : 0,
        insured: profileDetail && profileDetail.organization.insured !== null ? profileDetail.organization.insured : 0,
      })
    } else {
      //console.log(userData.planData)
      dispatch(getState({ country_id: userData && userData.planData.country_id }))
      let defaultCountry = { "value": userData && userData.planData.country_name, "label": userData && userData.planData.country_name }
      setBusinessProfileState({
        ...businessProfileState, businessName: '', email: '', phone: '', city: '', streetAddress: '', state: '', zip: '', country: defaultCountry, website: '',
        businessNameCls: '', emailCls: '', streetAddressCls: '', cityCls: '', stateCls: '', zipCls: '', countryCls: '', companyLogo: '', companyLogoSrc: '',
        businessNameErr: '', emailErr: '', phoneErr: '', streetAddressErr: '', cityErr: '', stateErr: '', zipErr: '', countryErr: '', bonded: 0, insured: 0,
        correctInput: '', wrongInput: constants.WRONG_INPUT, orgId: 1,
        companyHeader: '', companyHeaderSrc: '', companyHeaderErr: '', companyHeaderCls: '',
      })
    }
    setBusinessProfileModalShow(true)
  }
  // Save Business Profile Data 
  const saveBusinessProfileData = (e) => {
    // setTwoFactorVerifyModalShow(true)
    setBusinessProfileEditeState(true);
    let error = businessProfileState.wrongInput;
    let zip = businessProfileState.zip, companyLogo = businessProfileState.companyLogo, companyHeader = businessProfileState.companyHeader, country = businessProfileState.country.value, state = businessProfileState.state.value, businessName = businessProfileState.businessName,
      streetAddress = businessProfileState.streetAddress, city = businessProfileState.city, email = businessProfileState.email, phone = businessProfileState.phone, businessNameCls = '', countryCls = '', countryErr = '', emailCls = '',
      zipCls = '', stateCls = '', stateErr = '', zipErr = '', cityCls = '', businessNameErr = '', emailErr = '', streetAddressCls = '', streetAddressErr = '',
      cityErr = '', getError = false, website = businessProfileState.website, companyLogoErr = '', companyLogoCls = '', companyHeaderErr = '', companyHeaderCls = '';

    if (validateInputs('string', businessName) === 'empty') {
      businessNameErr = 'Please enter business name.';
      businessNameCls = error
      getError = true;
    } else if (validateInputs('string', businessName) === false) {
      businessNameErr = 'Please enter valid business name.';
      businessNameCls = error
      getError = true;
    } else if (businessName.length > 50) {
      businessNameErr = 'Please enter maximum 50 characters.';
      businessNameCls = error
      getError = true;
    }

    if (validateInputs('required', state) === 'empty') {
      stateErr = "Please select state.";
      stateCls = error;
      getError = true;
    }
    if (validateInputs('required', country) === 'empty') {
      countryErr = "Please select country.";
      countryCls = error;
      getError = true;
    }

    if (validateInputs('string', zip) === 'empty') {
      zipErr = 'Please enter zip.';
      zipCls = error
      getError = true;
    } else if (validateInputs('string', zip) === false) {
      zipErr = 'Please enter valid zip.';
      zipCls = error
      getError = true;
    } else if (zip.length > 10) {
      zipErr = 'Please enter maximum 10 characters.';
      zipCls = error
      getError = true;
    }

    if (validateInputs('string', streetAddress) === 'empty') {
      streetAddressErr = 'Please enter street Address.';
      streetAddressCls = error
      getError = true;
    } else if (validateInputs('string', streetAddress) === false) {
      streetAddressErr = 'Please enter valid street Address.';
      streetAddressCls = error
      getError = true;
    }

    if (validateInputs('string', city) === 'empty') {
      cityErr = 'Please enter city.';
      cityCls = error
      getError = true;
    } else if (validateInputs('string', city) === false) {
      cityErr = 'Please enter valid city.';
      cityCls = error
      getError = true;
    } else if (city.length > 50) {
      cityErr = 'Please enter maximum 50 characters.';
      cityCls = error
      getError = true;
    }

    if (validateInputs('email', email) === 'empty') {
      emailErr = 'Please enter email.';
      emailCls = error
      getError = true;
    } else if (validateInputs('email', email) === false) {
      emailErr = 'Please enter valid email.';
      emailCls = error
      getError = true;
    }

    // if (validateInputs('phoneNumberHyphon', phone) === 'empty') {
    //     phoneErr = 'Please enter phone.';
    //     phoneCls = error
    //     getError = true;
    // } else if (validateInputs('phoneNumberHyphon', phone) === false) {
    //     phoneErr = 'Please enter valid phone.';
    //     phoneCls = error
    //     getError = true;
    // }
    // if (phone && phone.length > 1 && phone.length > 15) {
    //     phoneErr = 'Please enter maximum 15 digits.';
    //     phoneCls = error
    //     getError = true;
    // }

    if (companyLogo && !companyLogo.name.match(/\.(jpg|jpeg|png)$/)) {
      companyLogoErr = 'Please select valid image.';
      companyLogoCls = error
      getError = true;
    } else if (companyLogo && companyLogo.size / 1024 / 1024 > 10) {
      companyLogoErr = 'Image size is grater than 10 MB.';
      companyLogoCls = error
      getError = true;
    }

    if (companyHeader && !companyHeader.name.match(/\.(jpg|jpeg|png)$/)) {
      companyHeaderErr = 'Please select valid image.';
      companyHeaderCls = error
      getError = true;
    } else if (companyHeader && companyHeader.size / 1024 / 1024 > 10) {
      companyHeaderErr = 'Image size is grater than 10 MB.';
      companyHeaderCls = error
      getError = true;
    }

    setBusinessProfileState({
      ...businessProfileState, zipCls, zipErr, countryCls, countryErr, stateCls, stateErr, businessNameCls, streetAddressCls, streetAddressErr, emailCls, businessNameErr, emailErr, cityCls, cityErr, companyLogoCls, companyLogoErr, companyHeaderCls, companyHeaderErr,
    })
    if (!getError) {
      setTwoFactorVerifyModalShow(true)
    }

    if (getError === false && emailErr === '' && stateErr === '' && countryErr === '' && zipErr === '' && businessNameErr === '' && streetAddressErr === '' && !twoFactorState.enable && getProfileDetailData?.data?.two_fa_enabled === 0) {
      const businessProfileData = new FormData()
      businessProfileData.append('city', city);
      businessProfileData.append('state', state);
      businessProfileData.append('country', country);
      businessProfileData.append('zip', zip);
      businessProfileData.append('email', email);
      businessProfileData.append('phone', phone);
      businessProfileData.append('name', businessName);
      businessProfileData.append('street_address', streetAddress);
      businessProfileData.append('website', website);
      businessProfileData.append('license_no', businessProfileState.license_no);
      businessProfileData.append('bonded', businessProfileState.bonded);
      businessProfileData.append('insured', businessProfileState.insured);
      if (companyLogo && companyLogo !== '') {
        businessProfileData.append('company_logo', companyLogo);
      } else {
        businessProfileData.append('company_logo', "");
      }

      if (companyHeader && companyHeader !== '') {
        businessProfileData.append('header_image', companyHeader);
      } else {
        businessProfileData.append('header_image', "");
      }
      let innerHTML = "<h5><img src=" + setImagePath(ALERT_ICON) + "> Confirm Business Name</h5><div className='mt-3'> <p>Business name you entered <strong>" + businessName + "</strong></p><p>Please confirm if your business name is accurate, once <strong>submitted you will not be able to change the business name.</strong> </p></div>";
      /* console.log(businessProfileData, 'businessProfileData') */
      /* let businessProfile = { name: businessName, street_address: streetAddress, city, state, country, zip, email, phone } */
      if (businessProfileState.orgId !== 1) {
        setLoader(true)
        dispatch(updateBusinessProfileDetail(businessProfileData))
        setTwoFactorVerifyModalShow(false)
      } else {
        Swal.fire({
          html: innerHTML,
          showCancelButton: true,
          confirmButtonText: 'Submit',
          cancelButtonText: 'Edit Name',
          reverseButtons: true,
          showCloseButton: true,
          customClass: "mycustom-alert",
          cancelButtonClass: 'cancel-alert-note',
        }).then((result) => {
          if (result.value) {
            setLoader(true)
            dispatch(updateBusinessProfileDetail(businessProfileData))
            setTwoFactorVerifyModalShow(false)
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // console.log('cancel')
          }
        })
      }
    }
  }
  console.log("businessDetails", businessInfo)

  const handleSaveDraft = async (publish = false) => {
    setLoader(true);
    if (businessInfo?.nameVal) {
      // const businessDetails = Object.keys(businessInfo).filter((key) => businessInfo[key]);
      const businessDetails = { ...businessInfo };
      const contactForm = {};
      Object.keys(visibleFields).forEach((field) => {
        contactForm[field] = visibleFields[field].required ? "required" : "optional";
      });

      const formData = new FormData();
      formData.append(
        "contact_form",
        JSON.stringify({
          business_details: businessDetails,
          contact_form: visibleFields,
          services,
        })
      );

      try {
        await dispatch(createBusinessForm(formData, publish));
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error saving draft:", error);
      }
    } else {
      errorNotification("Something went wrong Please try again!!");
    }
  };

  const saveServiceData = () => {
    let success = '';
    let error = constants.WRONG_INPUT;
    let service = serviceState.service, serviceErr = '', serviceCls = success, getError = false;

    if (validateInputs('required', service) === 'empty') {
      serviceErr = 'Please enter service.';
      serviceCls = error
      getError = true;
    }

    setServiceState({
      ...serviceState, serviceCls, serviceErr
    })

    if (getError === false && serviceErr === '') {
      setLoader(true)
      dispatch(addService({ name: service }))
      setAddServiceModalShow(false);
      setIsServicesAdded(true)
      setServiceState({
        service: '', serviceCls: '', serviceErr: '', servicesListOptions: [],
      });
    }
    setLoader(false)
  }

  // const saveServiceName = (e) => {
  //   e.preventDefault();

  //   if (!serviceState.serviceName) {
  //     setServiceState({ ...serviceState, serviceNameErr: "Service name is required." });
  //     return;
  //   }

  //   const newService = {
  //     label: serviceState.serviceName,
  //     value: serviceState.serviceName,
  //     visible: false,
  //   };

  //   const updatedServices = [
  //     ...services.filter((service) => service.value !== "AddNewServices"),
  //     newService,
  //     services.find((service) => service.value === "AddNewServices"),
  //   ];

  //   setServices(updatedServices);
  //   setAddServiceModalShow(false);
  //   setServiceState({ serviceName: '', serviceNameErr: '' });
  // };

  const handleServiceChange = (selectedOption) => {
    if (selectedOption.value === "AddNewServices") {
      setAddServiceModalShow(true)
      return
    }
    setServices((prev) => {
      return prev.map((service) => {
        if (service.value === selectedOption.value) {
          return {
            ...service,
            visible: !service.visible
          };
        }
        return service;
      });
    });
  };
  useEffect(() => {
    if (profileDetail) {
      const organization = profileDetail?.organization;
      if (organization) {
        const formattedAddress = formatAddress(organization);
        setAllAddress(formattedAddress || "-");
        setBusinessInfo((prev) => ({
          ...prev,
          name: true,
          nameVal: profileDetail?.organization?.name,
          email: true,
          emailVal: profileDetail?.organization?.email,
          phone: false,
          phoneVal: profileDetail?.organization?.phone,
          website: true,
          websiteVal: profileDetail?.organization?.website,
          address: false,
          addressVal: formattedAddress,
          logo: true,
          banner: true,
          licence: false,
          licenceVal: profileDetail?.organization?.licence,
          ...businessData,
          logoVal: baseUrl[0] + profileDetail?.organization?.company_logo,
          bannerVal: baseUrl[0] + profileDetail?.organization?.header_image,
        }));
      }
      setLoader(false);
    } else {
      dispatch(getProfileDetail());
      dispatch(viewBusinessForm());
    }
    if (listServiceWithSourceData && _.has(listServiceWithSourceData, 'data') && listServiceWithSourceData.success === true) {
      setLoader(false)
      let serviceOption = _.map(listServiceWithSourceData.data.service_types, (data) => { return { value: data.id, label: data.name } })
      // console.log("[...servicesData, serviceOption]", [...servicesData, serviceOption])

      // console.log('servicesData: ', servicesData);

      // const merged = [...servicesData, ...serviceOption];
      // const uniqueServices = Array.from(
      //   new Map(merged.map((item) => [item.value, item])).values()
      // );

      // console.log("uniqueServices", uniqueServices)
      // setServices([...servicesData, serviceOption])

      const merged = [...servicesData, ...serviceOption];

      const uniqueServices = Array.from(
        merged
          .sort((a, b) => (b.visible ? 1 : 0) - (a.visible ? 1 : 0)) // Prioritize objects with `visible`
          .reduce((map, item) => {
            if (!map.has(item.value)) {
              map.set(item.value, item);
            }
            return map;
          }, new Map())
          .values()
      );

      setServices(uniqueServices);
      setIsServicesAdded(false);
    }
    if (listServiceWithSourceData && _.has(listServiceWithSourceData, 'message') && listServiceWithSourceData.success === false) {
      setLoader(false)
      setAddServiceModalShow(false)
    }
  }, [listServiceWithSourceData, businessProfileDetailData, viewBusinessFormData]);


  useEffect(() => {
    dispatch(listServiceWithSource())
  }, []);

  useEffect(() => {
    dispatch(listServiceWithSource())
  }, [isServicesAdded]);

  useEffect(() => {
    if (businessProfileDetailData && _.has(businessProfileDetailData, 'message') && businessProfileDetailData.success) {
      setLoader(false)
      setBusinessProfileModalShow(false)
      let visibleData = {
        ...visibleFields,
        serviceInterested: { no: 1, label: "Service Interested In", required: true },
        firstName: { no: 2, label: "First Name", required: true },
        lastName: { no: 3, label: "Last Name", required: true },
        email: { no: 4, label: "Email", required: true }
      }
      dispatch(storeVisibleField(visibleData))
    } else {
      setFormDetails((prev) => ({
        ...prev,
        serviceInterested: { no: "1", label: "Service Interested In", required: true, visible: formData?.serviceInterested !== undefined ? true : true },
        firstName: { no: "2", label: "First Name", required: true, visible: formData?.firstName !== undefined ? true : true },
        lastName: { no: "3", label: "Last Name", required: true, visible: formData?.lastName !== undefined ? true : true },
        email: { no: "4", label: "Email", required: true, visible: formData?.email !== undefined ? true : true },
        phone: { no: "5", label: "Phone Number", required: false, visible: formData?.phone !== undefined ? true : false },
        title: { no: "6", label: "Title", required: false, visible: formData?.title !== undefined ? true : false },
        zipCode: { no: "7", label: "Zip Code", required: false, visible: formData?.zipCode !== undefined ? true : false },
        city: { no: "8", label: "City", required: false, visible: formData?.city !== undefined ? true : false },
        address1: { no: "9", label: "Street Address 1", required: false, visible: formData?.address1 !== undefined ? true : false },
        address2: { no: "10", label: "Street Address 2", required: false, visible: formData?.address2 !== undefined ? true : false },
        additionalInfo: { no: "11", label: "Additional Info", required: false, visible: formData?.additionalInfo !== undefined ? true : false },
      }))
      let visibleData = {
        ...formData,
        ...visibleFields,
        serviceInterested: { no: 1, label: "Service Interested In", required: true },
        firstName: { no: 2, label: "First Name", required: true },
        lastName: { no: 3, label: "Last Name", required: true },
        email: { no: 4, label: "Email", required: true }
      }
      dispatch(storeVisibleField(visibleData))
    }
    // setVisibleFields((prev)=>({
    //   ...prev,
    //   ...formData,
    //   serviceInterested: { no: 1, label: "Service Interested In", required: true },
    //   firstName: { no: 2, label: "First Name", required: true },
    //   lastName: { no: 3, label: "Last Name", required: true },
    //   email: { no: 4, label: "Email", required: true },
    // }))
  }, [viewBusinessFormData, businessProfileDetailData]);
  console.log("visibleFields", visibleFields)

  const renderBusinessInfo = useMemo(() => {
    const infoFields = [
      { key: "name", label: "Name" },
      { key: "email", label: "Email Address" },
      { key: "phone", label: "Phone Number" },
      { key: "website", label: "Website" },
      { key: "address", label: "Street Address" },
      { key: "logo", label: "Company Logo" },
      { key: "banner", label: "Banner Image" },
      { key: "licence", label: "License #" }
    ];

    return infoFields.map(({ key, label }) => (
      <div className="form-group col-lg-3 col-md-6 mb-lg-4" key={key}>
        <label className="custom-control custom-checkbox">
          <input
            type="checkbox"
            checked={businessInfo[key]}
            onChange={() => handleBusinessInfoChange(key)}
            className="custom-control-input"
          />
          <span className="custom-control-label">
            {label}
          </span>
        </label>
        <div>
          {key !== "address" &&
            <>
              {(key === "logo" || key === "banner") && profileDetail?.organization?.name !== "Default" ? (
                <div className="logo_imgg">
                  {key === "logo" &&
                    <img
                      src={profileDetail?.organization?.company_logo ? setImagePath(baseUrl[0] + profileDetail?.organization?.company_logo) : setImagePath(BIZZ_LOGO)}
                      alt=""
                      style={{ height: 50, width: 50, marginLeft: "40px" }}
                    />}
                  {key === "banner" &&
                    <img
                      src={profileDetail?.organization?.header_image ? setImagePath(baseUrl[0] + profileDetail?.organization?.header_image) : setImagePath(BIZZ_LOGO)}
                      alt=""
                      style={{ height: 50, width: 50, marginLeft: "40px" }}
                    />}
                </div>
              ) : (
                <div style={{ marginLeft: "40px" }} className="field-text">
                  {profileDetail?.organization?.name !== "Default" ? profileDetail?.organization?.[key] || "-" : "-"}
                </div>
              )}
            </>
          }
          {key === "address" && profileDetail?.organization?.name !== "Default" && (
            <div style={{ marginLeft: "40px" }}>
              <p>{allAddress}</p>
              {allAddress &&
                <a href={`https://www.google.com/maps/search/?api=1&query=${allAddress}`} rel="noopener noreferrer" target="_blank" className="text-link">
                  (Map)
                </a>
              }
            </div>
          )}
        </div>
      </div>
    ));
  }, [businessData, allAddress]);

  const CustomOption = ({ data, innerRef, innerProps, getValue }) => {
    console.log('data: From Customized Form ', data);
    const isSelected = data.visible;
    return (
      (data.value !== "AddNewServices") ?
        <div ref={innerRef} {...innerProps} className="custom-option">
          <label className="custom-control custom-checkbox">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => null}
              className="custom-control-input"
            />
            <span className="custom-control-label">{data.label}</span>
          </label>
        </div>
        :
        <div ref={innerRef} {...innerProps} >
          <a className="form-control">
            {data.label}
          </a>
        </div>
    );
  };

  const addExtraService = ({ innerRef, innerProps, isDisabled, children }) =>
    !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
        {children}
        <button
          type="button"
          className="btn text-link text-left btn-sm btn-block"
          onClick={(e) => setAddServiceModalShow(true)}
        >Add New Service</button>
      </div>
    ) : null;

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      borderRadius: "6px!important",
      height: "44px!important",
      width: "9rem!important",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '',
      "&:active": {
        backgroundColor: '#30CDCC',
        color: '#fff',
      },
      backgroundColor: state.isSelected ? '#30CDCC' : (state.isFocused ? '#DEEBFF' : ''),
    }),
    input: (provided) => ({
      ...provided,
      color: "#817F7F",
      height: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: "9rem!important",
      zIndex: 10,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
      padding: "0 16px",
      height: '35px',
    }),
  }

  const renderFormDetails = useMemo(() => {
    return (
      <>
        <div className="row mb-3">
          <div className="form-group col-lg-2 col-md-3 text-nowrap-md mb-lg-3">
            <label className="custom-control custom-checkbox label-disabled">
              <input
                type="checkbox"
                // checked={formDetails.serviceInterested.visible}
                checked={false}
                onChange={() => handleFormDetailChange("serviceInterested", formDetails.serviceInterested)}
                className="custom-control-input"
                disabled
              />
              <span className="custom-control-label font-weight-bold">{formDetails.serviceInterested.label}</span>
            </label>
          </div>
          {/* <div className="form-group col-lg-2 col-md-2 mb-lg-4 ml-3">
            <Select
              styles={selectStyle}
              className="floating-select"
              isSearchable={false}
              value={{
                value: formDetails.serviceInterested.required ? "required" : "optional",
                label: formDetails.serviceInterested.required ? "Required" : "Optional",
              }}
              onChange={(selectedOption) =>
                handleSelectChange("serviceInterested", formDetails.serviceInterested, selectedOption)
              }
              options={[
                { value: "required", label: "Required" },
                { value: "optional", label: "Optional" },
              ]}
            />
          </div> */}
          <div className="form-group col-lg-2 col-md-2 mb-lg-4 ml-3">
            <Select
              options={services}
              value={selectedServices}
              onChange={handleServiceChange}
              closeMenuOnSelect={false}
              components={{ MenuList: addExtraService, Option: CustomOption }}
              styles={selectStyle}
              placeholder={Array.isArray(services) ? services.filter(service => service?.visible)?.length > 0
                ? `${services.filter(service => service?.visible).length} Selected`
                : "Select Services"
                : "Select Services"}
            />
          </div>
        </div>

        <div className="row">
          {Object.keys(formDetails).map((detail) => {
            if (detail !== "serviceInterested") {
              return (
                <React.Fragment key={detail}>
                  <div className="form-group col-lg-2 col-md-2 mb-lg-4">
                    <label className={`custom-control custom-checkbox ${(detail === "firstName" || detail === "lastName" || detail === "email") ? "label-disabled" : ""}`}>
                      <input
                        type="checkbox"
                        checked={(detail === "firstName" || detail === "lastName" || detail === "email") ? false : visibleFields[detail] ? true : false}
                        onChange={() => handleFormDetailChange(detail, formDetails[detail])}
                        className={`custom-control-input remove-focus ${(detail === "firstName" || detail === "lastName" || detail === "email") ? "custom-disabled" : ""}`}
                        disabled={(detail === "firstName" || detail === "lastName" || detail === "email") ? true : false}
                        style={{ outline: "none" }}
                      />
                      <span className="custom-control-label font-weight-bold">{formDetails[detail].label}</span>
                    </label>
                  </div>
                  <div className="form-group col-lg-2 col-md-2 mb-lg-4">
                    <Select
                      styles={selectStyle}
                      className={`floating-select ${(detail === "firstName" || detail === "lastName" || detail === "email") ? "select-disabled" : ""}`}
                      isSearchable={false}
                      value={{
                        value: formDetails[detail].required ? "required" : "optional",
                        label: formDetails[detail].required ? "Required" : "Optional",
                      }}
                      onChange={(selectedOption) =>
                        handleSelectChange(detail, formDetails[detail], selectedOption)
                      }
                      options={[
                        { value: "required", label: "Required" },
                        { value: "optional", label: "Optional" },
                      ]}
                    />
                  </div>
                </React.Fragment>
              );
            }
            return null;
          })}
        </div>
      </>
    );
  }, [formData, servicesData]);


  return (
    <>
      <Loader loader={loader} />
      {!preview && (
        <div className="main-site fixed--header lead-page-hdr unfixed-page-title">
          <Header getMainRoute={"profile"} />
          <main className="site-body">
            <section className="page-title addbasicquoteheader">
              <div className="container">
                <div className="row">
                  <div className="col-lg-auto title--col">
                    <div>
                      <ol className="breadcrumb d-none d-lg-flex">
                        <li className="breadcrumb-item">
                          <Link to={"view-profile"}>My Profile</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Contact Form Setup
                        </li>
                      </ol>
                      <h2 className="title">Customize Contact Form</h2>
                    </div>
                  </div>
                  <div className="col-auto ml-auto d-flex align-items-center title-elems">
                    <Link to={"view-profile"} className="btn btn-dark mr-15 back">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.0001 19.92L8.48009 13.4C7.71009 12.63 7.71009 11.37 8.48009 10.6L15.0001 4.07996" stroke="#818186" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>Back</Link>
                    <div className="btn-divider mr-15 d-none d-lg-flex"></div>
                    <button onClick={handleSaveDraft} className="btn btn-secondary mr-15">Save as Draft</button>
                    <button onClick={() => setPreview(true)} className="btn btn-primary">
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <section className="middle-section pt-0">
              <div className="container">
                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <div className="card w-100">
                        <section className="business-info p-4">
                          <div className="card-header py-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <h2>Business Profile</h2>
                              <div className="d-flex">
                                <div className="card-header_btns d-flex justify-content-end align-items-center">
                                  <button onClick={(e) => openBusinessProfileModal(e)} className="btn btn-secondary ml-15">Edit</button>
                                </div>
                              </div>
                            </div>
                            <p>Choose the business information you’d like to include on the contact form.</p>
                          </div>
                          <div className="card-body px-3">
                            <div className="business-info-grid row">{renderBusinessInfo}</div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <div className="card w-100">
                        <section className="contact-form-details">
                          <div className="card-header pt-4">
                            <h2 className="mb-2">Contact Form Details</h2>
                            <p>Choose the information you’d like to gather from potential clients or customers.</p>
                          </div>
                          <div className="card-body px-3">
                            <div className="form-details-grid">{renderFormDetails}</div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          {/*
        <Modal show={addServiceModalShow} onHide={() => setAddServiceModalShow(false)} size="sm" centered>
        <Modal.Header closeButton>
        <Modal.Title>Add Service Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {serviceErrorMessage ? (
          <div className="errorCls errCommonCls mb-3">
              <img src={ERROR_ICON} alt="" /> {serviceErrorMessage}
          </div>
        ) : ''}
        <form>
          <div className="row">
              <div className="form-group col-md-12">
                  <div className="floating-label">
                      <input
                          placeholder="Service Name"
                          type="text"
                          name="serviceName"
                          value={serviceState.serviceName || ''}
                          onChange={(e) => {
                              setServiceState({ ...serviceState, serviceName: e.target.value, serviceNameErr: '' });
                              setServiceErrorMessage('');
                          }}
                          className="floating-input"
                      />
                      <label className="bold-text">Service Name</label>
                      {serviceState.serviceNameErr ? (
                          <span className="errorValidationMessage">{serviceState.serviceNameErr}</span>
                      ) : ''}
                  </div>
              </div>
          </div>
        </form>
        </Modal.Body>
        <Modal.Footer>
        <button type="button" className="btn btn-dark" onClick={() => setAddServiceModalShow(false)}>
          Cancel
        </button>
        <button
          type="button"
          onClick={(e) => saveServiceName(e)}
          className="btn btn-primary"
        >
          Save
        </button>
        </Modal.Footer>
        </Modal>
         */}
          <Modal Modal show={addServiceModalShow} onHide={() => setAddServiceModalShow(false)} className="" centered >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Service
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {serviceOptionMessage ? <div className="errorCls errCommonCls  mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{serviceOptionMessage}</div> : ''}
              <p className="p-small"><strong>Note:</strong> This service will automatically be saved for future use. </p>
              <form>
                <div className="floating-label">
                  <textarea ref={serviceTextRef} className="new-input floating-textarea floating-input" name="service" value={serviceState.service || ''} onChange={(e) => setServiceValue(e, 'required', null, null)} placeholder="Type service name here"></textarea>
                  <label class="bold-text">Service Name</label>
                  {serviceState.serviceErr ? <span className="errorValidationMessage"> {serviceState.serviceErr}</span> : ''}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-dark" onClick={() => setAddServiceModalShow(false)}>Cancel</button>
              <button type="button" onClick={() => saveServiceData()} className="btn btn-primary">Add</button>
            </Modal.Footer>
          </Modal >
          {/* Business Profile Modal*/}
          <Modal show={businessProfileModalShow} onHide={() => setBusinessProfileModalShow(false)} size="lg" className="" centered >
            <Modal.Header closeButton>
              <Modal.Title>
                {businessProfileState.orgId !== 1 ? businessProfileState.businessName : 'Add Business Information'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {businessProfileMessage ? <div className="errorCls errCommonCls mb-3"> <img src={ERROR_ICON} alt="" /> {businessProfileMessage} </div> : ""}
              {businessProfileState.orgId !== 1 ?
                '' : <div className="row">
                  <div className="col-md-8">
                    <div className="floating-label">
                      <input placeholder="Business Name" type="text" disabled={businessProfileState.orgId !== 1 ? true : false} name="businessName" value={businessProfileState.businessName || ''} onChange={(e) => setBusinessProfileValue(e, 'string', 50, null)} className="floating-input" />
                      <label class="bold-text">Business Name</label>
                      {businessProfileState.businessNameErr ? <span className="errorValidationMessage"> {businessProfileState.businessNameErr}</span> : ''}
                    </div>
                  </div>
                </div>}
              <div className="row">
                <div className="col-md-6">
                  <div className="floating-label">
                    <input placeholder="Email Address" type="email" name="email" value={businessProfileState.email || ''} onChange={(e) => setBusinessProfileValue(e, 'email', null, null)} className="floating-input" />
                    <label class="bold-text">Email Address</label>
                    {businessProfileState.emailErr ? <span className="errorValidationMessage"> {businessProfileState.emailErr}</span> : ''}
                  </div>
                </div>
                <div className="col-md-5">
                  {/* <div className={"floating-label " + businessProfileState.phoneCls}> */}
                  <div className="floating-label">
                    <input placeholder="Phone Number" type="text" name="phone" value={businessProfileState.phone || ''} onChange={(e) => setBusinessProfileValue(e, 'phoneNumberHyphon', 15, null)} className="floating-input" />
                    <label class="bold-text">Phone Number</label>
                    {/* {businessProfileState.phoneErr ? <span className="errorValidationMessage"> {businessProfileState.phoneErr}</span> : ''} */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="floating-label">
                    <input placeholder="Street Address" type="text" name="streetAddress" value={businessProfileState.streetAddress || ''} onChange={(e) => setBusinessProfileValue(e, 'string', null, null)} className="floating-input" />
                    <label class="bold-text">Street Address</label>
                    {businessProfileState.streetAddressErr ? <span className="errorValidationMessage"> {businessProfileState.streetAddressErr}</span> : ''}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="floating-label">
                    <input placeholder="City" type="text" name="city" value={businessProfileState.city || ''} onChange={(e) => setBusinessProfileValue(e, 'string', 50, null)} className="floating-input" />
                    <label class="bold-text">City</label>
                    {businessProfileState.cityErr ? <span className="errorValidationMessage"> {businessProfileState.cityErr}</span> : ''}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-row">
                    <div className="col-8">
                      <div className="floating-label">
                        <Select
                          styles={selectStyle}
                          className="floating-select"
                          components={{ ValueContainer: CustomValueContainer }}
                          options={stateOptions}
                          isSearchable={false}
                          value={businessProfileState.state}
                          placeholder="State"
                          onChange={(data) => setBusinessProfileState({ ...businessProfileState, state: data, stateCls: '', stateErr: '' })}
                        />
                        {businessProfileState.stateErr ? <span className="errorValidationMessage"> {businessProfileState.stateErr}</span> : ''}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="floating-label">
                        <input placeholder="Zip" type="text" name="zip" value={businessProfileState.zip || ''} onChange={(e) => setBusinessProfileValue(e, 'string', 10, null)} className="floating-input" />
                        <label class="bold-text">Zip</label>
                        {businessProfileState.zipErr ? <span className="errorValidationMessage"> {businessProfileState.zipErr}</span> : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="floating-label">
                    <Select
                      styles={selectStyle}
                      className="floating-select"
                      components={{ ValueContainer: CustomValueContainer }}
                      options={countryOptions}
                      isSearchable={false}
                      // isDisabled={true}
                      value={businessProfileState.country}
                      placeholder="Country"
                      onChange={(data) => onChangeCountry(data)}
                    />
                    {businessProfileState.countryErr ? <span className="errorValidationMessage"> {businessProfileState.countryErr}</span> : ''}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="floating-label">
                    <input placeholder="Website (Optional)" value={businessProfileState.website || ''} onChange={(e) => setBusinessProfileState({ ...businessProfileState, website: e.target.value })} type="text" className="floating-input" />
                    <label class="bold-text">Website <span>(Optional)</span></label>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="floating-label">
                    <input placeholder="license #" value={businessProfileState.license_no || ''} onChange={(e) => setBusinessProfileState({ ...businessProfileState, license_no: e.target.value })} type="text" className="floating-input" />
                    <label class="bold-text">license # <span>(Optional)</span></label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="bonded" onChange={(e) => setBusinessProfileState({ ...businessProfileState, bonded: e.target.checked === true ? 1 : 0 })} checked={businessProfileState.bonded} className="custom-control-input" id='flag1' />
                    <label className="custom-control-label" htmlFor='flag1'>Bonded</label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="insured" onChange={(e) => setBusinessProfileState({ ...businessProfileState, insured: e.target.checked === true ? 1 : 0 })} checked={businessProfileState.insured} className="custom-control-input" id='flag2' />
                    <label className="custom-control-label" htmlFor='flag2'>Insured</label>
                  </div>
                </div>
                {/* <div className="col-md-9 mt-3">
                                <div className="company--logo">
                                    <label className="logo-label">Company Logo <span>(Optional)</span></label>
                                    <div className="logo_wrapp">
                                        <div className="logo_imgg"><img src={businessProfileState.companyLogoSrc ? setImagePath(businessProfileState.companyLogoSrc) : setImagePath(BIZZ_LOGO)} alt="" style={{ height: 50, width: 50 }} /></div>
                                        <div className="logo_upload ">
                                            <div className="logo_upload-file">
                                                <div className="custom-file">
                                                    <input type="file" onChange={(e) => onSelectFile(e)} className="custom-file-input " id="browse-file" accept='image/*' />
                                                    <input type="file" className="custom-file-input" id="customFile" />
                                                    <label className="custom-file-label" htmlFor="customFile">Upload File</label>
                                                </div>
                                                {businessProfileState.companyLogo !== '' ?
                                                    <a href="#delete" onClick={(e) => deleteCompanyLogo(e)} className="delete_file"><img src={DELETE_SVG} alt="" /> Delete</a>
                                                    : ''}
                                            </div>
                                            <div className="logo_upload-file-instruction">
                                                Upload .png, .jpeg, .jpg file with max 10MB size
                                            </div>
                                            <div className={businessProfileState.companyLogoCls}>
                                                {businessProfileState.companyLogoErr ? <span className="errorValidationMessage"> {businessProfileState.companyLogoErr}</span> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-12 company--logo">
                      <label className="logo-label">Business Brand <span>(Optional)</span></label>
                      <p>Your business logo & business banner is automatically included in quotes, contracts and email notifications. </p>
                      <p><b>Note:</b> Upload .png, .jpeg, .svg file with max 100MB size </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-12 upload-components">
                      <div className="logo_wrapp col-md-6">
                        <div className="logo_imgg p-0"><img src={businessProfileState.companyLogoSrc ? setImagePath(businessProfileState.companyLogoSrc) : setImagePath(BIZZ_LOGO)} alt="" style={{ height: 50, width: 50 }} /></div>
                        <div className="logo_upload ">
                          <div className="logo_upload-file">
                            <div className="custom-file">
                              <input type="file" onChange={(e) => onSelectFile(e)} className="custom-file-input " id="browse-file" accept='image/*' />
                              <label className="custom-file-label p-0 bg-transparent" htmlFor="customFile">Upload Logo</label>
                            </div>
                            {/* {businessProfileState.companyLogo !== '' ?
                                                        <a href="#delete" onClick={(e) => deleteCompanyLogo(e)} className="delete_file"><img src={DELETE_SVG} alt="" /> Delete</a>
                                                        : ''} */}
                            {(businessProfileState.companyLogo || businessProfileState.companyLogoSrc) && <a href="#delete" onClick={(e) => deleteCompanyLogo(e)} className="delete_file"><img src={DELETE_SVG} alt="" /></a>}
                          </div>
                          <div className={businessProfileState.companyLogoCls}>
                            {businessProfileState.companyLogoErr ? <span className="errorValidationMessage"> {businessProfileState.companyLogoErr}</span> : ''}
                          </div>
                        </div>
                      </div>
                      <div className="logo_wrapp col-md-6">
                        <div className="logo_imgg p-0"><img src={businessProfileState.companyHeaderSrc ? setImagePath(businessProfileState?.companyHeaderSrc) : setImagePath(BIZZ_LOGO)} alt="" style={{ height: 50, width: 50 }} /></div>
                        <div className="logo_upload ">
                          <div className="logo_upload-file">
                            <div className="custom-file">
                              <input type="file" onChange={(e) => onSelectHeaderFile(e)} className="custom-file-input " id="browse-file" accept='image/*' />
                              <label className="custom-file-label bg-transparent" htmlFor="customFile">Upload Business Banner</label>
                            </div>
                            {/* {businessProfileState.companyHeader !== '' ?
                                                        <a href="#delete" onClick={(e) => deleteCompanyLogo(e)} className="delete_file"><img src={DELETE_SVG} alt="" /> Delete</a>
                                                        : ''} */}
                            {(businessProfileState.companyHeader || businessProfileState.companyHeaderSrc) && <a href="#delete" onClick={() => deleteHeaderImageFunc()} className="delete_file"><img src={DELETE_SVG} alt="" /></a>}
                          </div>
                          <div className="logo_upload-file-instruction">
                            820 x 312 pix (Recommended Dimensions)
                          </div>
                          <div className={businessProfileState.companyHeaderCls}>
                            {businessProfileState.companyHeaderErr ? <span className="errorValidationMessage"> {businessProfileState.companyHeaderErr}</span> : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="mt-3">
              <button type="button" className="btn btn-dark" onClick={() => setBusinessProfileModalShow(false)}>Cancel</button>
              <button type="button" onClick={(e) => saveBusinessProfileData(e)} className="btn btn-primary">Save</button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </div>
      )}
      {preview &&
        <PreviewContactForm
          setPreview={() => setPreview(false)}
          formDetails={visibleFields}
          businessInfo={businessInfo}
          services={services}
          handleSaveDraft={handleSaveDraft}
        />
      }
    </>
  );
};

export const CustomizeContactForm = CustomizeContact;