import {
    SUCCESS_CREATE_BUSINESS_FORM, ERROR_CREATE_BUSINESS_FORM,
    SUCCESS_VIEW_PUBLIC_FORM, ERROR_VIEW_PUBLIC_FORM,
    SUCCESS_VIEW_BUSINESS_FORM, ERROR_VIEW_BUSINESS_FORM,
    SUCCESS_PUBLISH_BUSINESS_FORM, ERROR_PUBLISH_BUSINESS_FORM,
    SUCCESS_CREATE_CONTACT, ERROR_CREATE_CONTACT,
} from './businessForm.action';
import { DEFAULT_STATE } from './businessForm.state';

export const businessFormReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case 'STORE_FIELD':
            return {
              ...state,
              visibleFields: action.payload.data,
            };
        case 'ADD_FIELD':
            return {
              ...state,
              visibleFields: {
                ...state.visibleFields,
                [action.payload.field]: action.payload.info,
              },
            };
        case 'UPDATE_FIELD':
            return {
              ...state,
              visibleFields: {
                ...state.visibleFields,
                [action.payload.field]: { ...action.payload.info, required: action.payload.requireds },
              },
            };
        case 'REMOVE_FIELD':
            const { [action.payload.field]: _, ...rest } = state.visibleFields;
            return {
                ...state,
                visibleFields: rest,
            };
        case SUCCESS_CREATE_BUSINESS_FORM:
            const createBusinessFormData = action.data;
            return { ...state, createBusinessFormData };
        case ERROR_CREATE_BUSINESS_FORM:
            const errorCreateBusinessFormData = action.data;
            return { ...state, createBusinessFormData: errorCreateBusinessFormData };
        case SUCCESS_VIEW_PUBLIC_FORM:
            const viewPublicFormData = action.data.data;
            return { ...state, viewPublicFormData };
        case ERROR_VIEW_PUBLIC_FORM:
            const errorViewPublicFormData = action.data;
            return { ...state, viewPublicFormData: errorViewPublicFormData };
        case SUCCESS_VIEW_BUSINESS_FORM:
            const viewBusinessFormData = action.data.data;
            return { ...state, viewBusinessFormData };
        case ERROR_VIEW_BUSINESS_FORM:
            const errorViewBusinessFormData = action.data;
            return { ...state, viewBusinessFormData: errorViewBusinessFormData };
        case SUCCESS_PUBLISH_BUSINESS_FORM:
            const publishBusinessFormData = action.data;
            return { ...state, publishBusinessFormData };
        case ERROR_PUBLISH_BUSINESS_FORM:
            const errorPublishBusinessFormData = action.data;
            return { ...state, publishBusinessFormData: errorPublishBusinessFormData };
        case SUCCESS_CREATE_CONTACT:
            const createContactFormData = action.data;
            return { ...state, createContactFormData };
        case ERROR_CREATE_CONTACT:
            const errorCreateContactFormData = action.data;
            return { ...state, createContactFormData: errorCreateContactFormData };
        default:
            return state;
    }
};
