// Create
export const CREATE_BUSINESS_FORM = "CREATE_BUSINESS_FORM";
export const createBusinessForm = (data,publish) => ({ type: CREATE_BUSINESS_FORM, data,publish });
export const SUCCESS_CREATE_BUSINESS_FORM = "SUCCESS_CREATE_BUSINESS_FORM";
export const ERROR_CREATE_BUSINESS_FORM = "ERROR_CREATE_BUSINESS_FORM";
export const createBusinessFormResponse = (type, data) => ({ type, data });

// View Public Form
export const VIEW_PUBLIC_FORM = "VIEW_PUBLIC_FORM";
export const viewPublicForm = (data) => ({ type: VIEW_PUBLIC_FORM, data });
export const SUCCESS_VIEW_PUBLIC_FORM = "SUCCESS_VIEW_PUBLIC_FORM";
export const ERROR_VIEW_PUBLIC_FORM = "ERROR_VIEW_PUBLIC_FORM";
export const viewPublicFormResponse = (type, data) => ({ type, data });

// View
export const VIEW_BUSINESS_FORM = "VIEW_BUSINESS_FORM";
export const viewBusinessForm = (data) => ({ type: VIEW_BUSINESS_FORM, data });
export const SUCCESS_VIEW_BUSINESS_FORM = "SUCCESS_VIEW_BUSINESS_FORM";
export const ERROR_VIEW_BUSINESS_FORM = "ERROR_VIEW_BUSINESS_FORM";
export const viewBusinessFormResponse = (type, data) => ({ type, data });

// Publish
export const PUBLISH_BUSINESS_FORM = "PUBLISH_BUSINESS_FORM";
export const publishBusinessForm = (data) => ({ type: PUBLISH_BUSINESS_FORM, data });
export const SUCCESS_PUBLISH_BUSINESS_FORM = "SUCCESS_PUBLISH_BUSINESS_FORM";
export const ERROR_PUBLISH_BUSINESS_FORM = "ERROR_PUBLISH_BUSINESS_FORM";
export const publishBusinessFormResponse = (type, data) => ({ type, data });

// Create Contact
export const CREATE_BUSINESS_CONTACT_FORM = 'CREATE_BUSINESS_CONTACT_FORM';
export const createBusinessContactForm = (data) => ({ type: CREATE_BUSINESS_CONTACT_FORM, data });
export const SUCCESS_CREATE_CONTACT = "SUCCESS_CREATE_CONTACT";
export const ERROR_CREATE_CONTACT = "ERROR_CREATE_CONTACT";
export const createBusinessContactFormResponse = (type, data) => ({ type, data });
export const addVisibleField = (field,info) => ({type: 'ADD_FIELD',payload: { field, info },});
export const storeVisibleField = (data) => ({type: 'STORE_FIELD',payload: { data },});
export const updateVisibleField = (field, info, required) => ({type: 'UPDATE_FIELD',payload: { field, info, required },});
export const removeVisibleField = (field) => ({type: 'REMOVE_FIELD',payload: { field }});
  