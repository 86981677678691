import { put, takeLatest, all } from 'redux-saga/effects';
import {
    createBusinessFormResponse, SUCCESS_CREATE_BUSINESS_FORM, ERROR_CREATE_BUSINESS_FORM, CREATE_BUSINESS_FORM,
    viewPublicFormResponse, SUCCESS_VIEW_PUBLIC_FORM, ERROR_VIEW_PUBLIC_FORM, VIEW_PUBLIC_FORM,
    viewBusinessFormResponse, SUCCESS_VIEW_BUSINESS_FORM, ERROR_VIEW_BUSINESS_FORM, VIEW_BUSINESS_FORM,
    publishBusinessFormResponse, SUCCESS_PUBLISH_BUSINESS_FORM, ERROR_PUBLISH_BUSINESS_FORM, PUBLISH_BUSINESS_FORM,
    createBusinessContactFormResponse, SUCCESS_CREATE_CONTACT, ERROR_CREATE_CONTACT, CREATE_BUSINESS_CONTACT_FORM
} from './businessForm.action';
import {
    createBusinessApi,
    publishBusinessApi,
    getBusinessDetailApi,
    getBusinessListApi,
    createBusinessContactApi
} from '../../../api/index';
import { successNotification, errorNotification } from '../../common/notification-alert';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { setImagePath } from '../../common/custom';
import ALERT_ICON from "../../assets/images/alert-icn.svg";
import { VIEW_PROFILE } from '../../routing/routeContants';
import history from '../../routing/history';

// Create Business Form
function* createBusinessFormRequest({ data, publish }) {
    try {
        const response = yield createBusinessApi(data);
        if (response.success && _.has(response, 'data')) {
            if (publish == true) {
                yield put({ type: PUBLISH_BUSINESS_FORM, payload: null });
                return;
            }
            successNotification(response.data.message);
            yield put(createBusinessFormResponse(SUCCESS_CREATE_BUSINESS_FORM, response.data));
        } else {
            const errorMessage = response.error_message || 'Failed to create business form';
            errorNotification(errorMessage);
            yield put(createBusinessFormResponse(ERROR_CREATE_BUSINESS_FORM, response.error_message));
        }
    } catch (error) {
        errorNotification(error.message);
        yield put(createBusinessFormResponse(ERROR_CREATE_BUSINESS_FORM, error.message));
    }
}

export function* createBusinessFormWatcher() {
    yield takeLatest(CREATE_BUSINESS_FORM, createBusinessFormRequest);
}

// View Public Form
function* viewPublicFormRequest({ data }) {
    try {
        const response = yield getBusinessListApi(data);
        if (response.success && _.has(response, 'data')) {
            yield put(viewPublicFormResponse(SUCCESS_VIEW_PUBLIC_FORM, response.data));
        } else {
            yield put(viewPublicFormResponse(ERROR_VIEW_PUBLIC_FORM, response.error_message || 'Failed to load public form'));
        }
    } catch (error) {
        errorNotification(error.message);
        yield put(viewPublicFormResponse(ERROR_VIEW_PUBLIC_FORM, error.message));
    }
}

export function* viewPublicFormWatcher() {
    yield takeLatest(VIEW_PUBLIC_FORM, viewPublicFormRequest);
}

// View Business Form
function* viewBusinessFormRequest({ data }) {
    try {
        const response = yield getBusinessDetailApi(data);
        if (response.success && _.has(response, 'data')) {
            yield put(viewBusinessFormResponse(SUCCESS_VIEW_BUSINESS_FORM, response.data));
        } else {
            yield put(viewBusinessFormResponse(ERROR_VIEW_BUSINESS_FORM, response.error_message || 'Failed to load business form'));
        }
    } catch (error) {
        errorNotification(error.message);
        yield put(viewBusinessFormResponse(ERROR_VIEW_BUSINESS_FORM, error.message));
    }
}

export function* viewBusinessFormWatcher() {
    yield takeLatest(VIEW_BUSINESS_FORM, viewBusinessFormRequest);
}

// Publish Business Form
function* publishBusinessFormRequest({ data }) {
    try {
        console.log("publishBusinessFormRequest")
        const response = yield publishBusinessApi(data);
        if (response.success && _.has(response, 'data')) {
            Swal.fire({
                html: "<h5>Success</h5><div className='mt-3'>" +
                    "<p>The contact form updates have been successfully published.</p>" +
                    "</div>",
                confirmButtonText: 'Close',
                customClass: "mycustom-alert",
            });
            // successNotification(response.data.message);
            yield put(publishBusinessFormResponse(SUCCESS_PUBLISH_BUSINESS_FORM, response.data));
            history.push(`${VIEW_PROFILE}#contact-form-setting`);
        } else {
            const errorMessage = response.error_message || 'Failed to publish business form';
            errorNotification(errorMessage);
            yield put(publishBusinessFormResponse(ERROR_PUBLISH_BUSINESS_FORM, response.error_message));
        }
    } catch (error) {
        errorNotification(error.message);
        yield put(publishBusinessFormResponse(ERROR_PUBLISH_BUSINESS_FORM, error.message));
    }
}

export function* publishBusinessFormWatcher() {
    yield takeLatest(PUBLISH_BUSINESS_FORM, publishBusinessFormRequest);
}

// Create Business Contact Form Request
function* createBusinessContactFormRequest({ data }) {
    try {
        const response = yield createBusinessContactApi(data);
        if (response.success && _.has(response, 'data')) {
            successNotification("Thank you for reaching out to us. We will be in touch with you shortly.");  // Show success notification
            yield put(createBusinessContactFormResponse(SUCCESS_CREATE_CONTACT, response.data));
        } else {
            const errorMessage = response.data.message || 'Failed to create business contact';
            errorNotification(errorMessage);
            yield put(createBusinessContactFormResponse(ERROR_CREATE_CONTACT, response.error_message));
        }
    } catch (error) {
        errorNotification(error.message);
        yield put(createBusinessContactFormResponse(ERROR_CREATE_CONTACT, error.message));
    }
}

export function* createBusinessContactFormWatcher() {
    yield takeLatest(CREATE_BUSINESS_CONTACT_FORM, createBusinessContactFormRequest);
}

export default function* businessFormSaga() {
    yield all([
        createBusinessFormWatcher(),
        viewPublicFormWatcher(),
        viewBusinessFormWatcher(),
        publishBusinessFormWatcher(),
        createBusinessContactFormWatcher()
    ]);
}